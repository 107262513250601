// 一套默认主题以及一套暗黑主题

export const themes = {
	dark: {
		// 公共：主题颜色
		primaryColor: '#000',
		primaryTextColor: '#fff', // used
		BackgroundColor: '#16161a',
		HeadlineColor: '#fffffe',
		ParagraphColor: '#94a1b2',
		ButtonColor: '#7f5af0',
		ButtonTextColor: '#fffffe',
		StrokeColor: '#010101',
		MainColor: '#fffffe',
		HighlightColor: '#7f5af0',
		SecondaryColor: '#72757e',
		TertiaryColor: '#2cb67d', // used
		TertiaryColor60: '#2cb67d60', // used
		TertiaryColor80: '#2cb67d80', // used

		// Home.vue的样式
		sidebarBackgroundColor: '#202123',

		// LeftSideBar.vue的样式
		rootDivColor: 'rgba(255, 255, 255, 0.82)',
		newChatButtonBackgroudColor: 'rgba(47, 49, 54, 0.5)',
		infiniteListItemSelectedBackgroundColor: '#343541',
		infiniteListItemHoverBackgroundColor: '#40414f',
		elDividerBackgroundColor: 'rgb(73, 72, 72)',
		scrollbarThumbBackgroundColor: '#8e8ea0',
		borderRight: '0',

		// ChatWindow.vue的样式
		elHeaderBorderColor: 'rgb(73, 72, 72)',
		elHeaderSvgIconColor: 'rgba(255, 255, 255, 0.82)',
		chatFriendTextColor: '#ececf1',
		chatFriendBackgroundColor: '#242629',
		chatFriendTextBackgroundColor: '#393d42',
		infoTimeSpanColor: 'rgb(101, 104, 115)',
		chatInputsBagroundColor: 'rgb(66, 70, 86)',
		chatInputsBorderColor: 'rgb(80, 85, 103)',
		chatInputsSendHoverBack: '#4d6858',

		// 文本
		// sidebarTextColor: '#ffffff',
		// sidebarTextColor1: '#ffffff',
		// sidebarTextColor2: '#ffffff80',
		// sidebarTextColor3: '#ececf1',
		// sidebarTextColor4: '#8e8ea0',
		// sidebarTextColor5: '#343541',
		// sidebarTextColor6: '#000000',

		// 背景
		// sidebarBackgroundColor: '#202123',
		// sidebarBackgroundColor1: '#ffffff0d',
		// sidebarBackgroundColor2: '#fae69e',
		// sidebarBackgroundColor3: '#40414f',
		// sidebarBackgroundColor4: '#343541',
		// sidebarBackgroundColor5: '#202123',

		// sidebarActiveTextColor: '#7f5af0',
		// sidebarActiveBackgroundColor: '#16161a',
		// sidebarActiveBorderColor: '#7f5af0',
		// sidebarActiveIconColor: '#7f5af0',
		// sidebarIconColor: '#94a1b2',
		// sidebarHoverBackgroundColor: '#16161a',
		// sidebarHoverTextColor: '#7f5af0',
		// sidebarHoverIconColor: '#7f5af0',
		// sidebarHoverBorderColor: '#7f5af0',
		// sidebarBorderColor: '#16161a',
		// sidebarBorderWidth: '1px',
		// sidebarBorderRadius: '0px',
		// sidebarBorderStyle: 'solid',

		// primaryColor: '#000',
		// primaryTextColor: '#fff',
		// BackgroundColor: '#16161a',
		// HeadlineColor: '#fffffe',
		// ParagraphColor: '#94a1b2',
		// ButtonColor: '#7f5af0',
		// ButtonTextColor: '#fffffe',
		// StrokeColor: '#010101',
		// MainColor: '#fffffe',
		// HighlightColor: '#7f5af0',
		// SecondaryColor: '#72757e',
		// TertiaryColor: '#2cb67d',
	},
	light: {
		// 公共：主题颜色
		primaryColor: '#fff',
		primaryTextColor: '#000', // used
		BackgroundColor: '#fffffe',
		HeadlineColor: '#000',
		ParagraphColor: '#5f6368',
		ButtonColor: '#1a73e8',
		ButtonTextColor: '#fff',
		StrokeColor: '#eaeaea',
		MainColor: '#000',
		HighlightColor: '#1a73e8',
		SecondaryColor: '#5f6368',
		TertiaryColor: '#2cb67d', // used
		TertiaryColor60: 'rgba(44, 182, 125, 0.6)', // used
		TertiaryColor80: 'rgba(44, 182, 125, 0.8)', // used

		// Home.vue的样式
		sidebarBackgroundColor: '#fffffe',

		// LeftSideBar.vue的样式
		rootDivColor: 'rgba(0, 0, 0, 0.82)',
		newChatButtonBackgroudColor: '#fff',
		infiniteListItemSelectedBackgroundColor: '#efefef',
		infiniteListItemHoverBackgroundColor: '#f0f2f5',
		elDividerBackgroundColor: '#dadce0',
		scrollbarThumbBackgroundColor: '#c6c6c6',
		borderRight: '1px solid #dadce0',

		// ChatWindow.vue的样式
		elHeaderBorderColor: '#dadce0',
		elHeaderSvgIconColor: 'rgba(0, 0, 0, 1)',
		chatFriendTextColor: '#000',
		chatFriendBackgroundColor: '#f2f4f6',
		chatFriendTextBackgroundColor: '#e0e0e0',
		infoTimeSpanColor: '#5f6368',
		chatInputsBagroundColor: '#fff',
		chatInputsBorderColor: '#dadce0',
		chatInputsSendHoverBack: '#1a73e8',
	},
}

/* 
暗黑模式：
【Elements】元素
Background #16161a
Headline  #fffffe
Paragraph #94a1b2
Button #7f5af0
Button text  #fffffe

【Illustration】 插图
Stroke #010101
Main #fffffe
Highlight #7f5af0
Secondary #72757e
Tertiary #2cb67d 

----------

Elements
Background
#242629
Headline
#fffffe
Sub headline
#94a1b2
Card background
#16161a
Card heading
#fffffe
Card paragraph
#94a1b2
Icons
Stroke
#010101
Main
#fffffe
Highlight
#7f5af0
Secondary
#72757e
Tertiary
#2cb67d
 
---------- 

Background
#16161a
Headline
#fffffe
Paragraph
#94a1b2
Card background
#242629
Card headline
#fffffe
Card paragraph
#94a1b2
Card tag background
#7f5af0
Card tag text
#fffffe
Card highlight
#fffffe


// 官网的暗黑模式
--color-primary: #19c37d;
--color-secondary: #715fde;
--color-error: #ef4146;
--gradient-primary: linear-gradient(90deg,#a29bd4,#989fdd);
--text-primary: #202123;
--text-default: #353740;
--text-secondary: #6e6e80;
--text-disabled: #acacbe;
--text-error: var(--color-error);


背景色：
#ffffff0d
#fae69e
#40414f
#343541
#202123

文本色
#ffffff
#ffffff80
#ececf1
#8e8ea0
#343541
#000000

*/
