class Storage {
    constructor(name) {
        this.name = 'storage';
    }
    //设置缓存(expires：过期时长，单位秒)
    set (key, value, expires) {
        let options = {
            key: key || '',
            value: value || '',
            expires: expires * 1000 || "",
            startTime: new Date().getTime()
        }
        if (options.expires) {
            localStorage.setItem(options.key, JSON.stringify(options));
        } else {
            //如果options.expires没有设置，就判断一下value的类型
            let type = Object.prototype.toString.call(options.value);
            //如果value是对象或者数组对象的类型，就先用JSON.stringify转一下，再存进去
            if (Object.prototype.toString.call(options.value) == '[object Object]') {
                options.value = JSON.stringify(options.value);
            }
            if (Object.prototype.toString.call(options.value) == '[object Array]') {
                options.value = JSON.stringify(options.value);
            }
            localStorage.setItem(options.key, options.value);
        }
    }
    //拿到缓存
    get (key) {
        let item = localStorage.getItem(key);
        //先将拿到的试着进行json转为对象的形式
        try {
            item = JSON.parse(item);
        } catch (error) {
            //如果不行就不是json的字符串，就直接返回
            item = item;
        }
        //如果有startTime的值，说明设置了失效时间
        if (item && item.expires) {
            let date = new Date().getTime();
            //何时将值取出减去刚存入的时间，与item.expires比较，如果大于就是过期了，如果小于或等于就还没过期
            if (date - item.startTime > item.expires) {
                //缓存过期，清除缓存，返回false
                localStorage.removeItem(key);
                return false;
            } else {
                //缓存未过期，返回值
                return item.value;
            }
        } else {
            //如果没有设置失效时间，直接返回值
            return item;
        }
    }
    //移出缓存
    remove (key) {
        localStorage.removeItem(key);
    }
    //移出全部缓存
    clear () {
        localStorage.clear();
    }
}


export default new Storage(); 