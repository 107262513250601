import localStorageUtil from '@/utils/localStorageUtil.js'
import { convasitionHistoryList2 } from '@/mock/chatList.js'
import { parseTime } from '@/utils/util'

console.log('chatHistoryList:', localStorageUtil.get('chatHistoryList'))

// 模拟chatList
/* if (localStorageUtil.get('chatHistoryList') == null) {
	localStorageUtil.set('chatHistoryList', convasitionHistoryList2)
} */

if (localStorageUtil.get('chatHistoryList') == null) {
	localStorageUtil.set('chatHistoryList', [])
}

let state = {
	chatHistoryList: localStorageUtil.get('chatHistoryList') || [],
	activeChatIndex: 0,
}

//唯一可以修改仓库数据地方【工人】
let mutations = {
	// 设置index为当前对话
	SET_ACTIVECHATINDEX(state, index) {
		state.activeChatIndex = index
	},
	// 添加新的空对话并设置为当前对话
	ADD_CHAT(state) {
		var date = new Date()
		var time = date.getTime()
		// console.log(this.$parseTime(time))
		var newChat = {
			id: time,
			topic: '',
			memoryPrompt: '',
			messages: [
				{
					role: 'assistant',
					content: '有什么可以帮你的吗',
					date: parseTime(time),
				},
			],
		}
		var arr = localStorageUtil.get('chatHistoryList')
		arr.unshift(newChat)
		state.chatHistoryList = arr
		localStorageUtil.set('chatHistoryList', arr)
		state.activeChatIndex = 0
	},
	// 添加新对话（非空）
	ADD_CHATLIST(state, newChat) {
		console.log('ADD_CHATLIST:', newChat)
		var arr = localStorageUtil.get('chatHistoryList')
		arr.unshift(newChat)
		state.chatHistoryList = arr
		localStorageUtil.set('chatHistoryList', arr)
	},
	// 更新对话主题
	EDIT_TOPIC(state, { index, value }) {
		var arr = localStorageUtil.get('chatHistoryList')
		arr[index].topic = value
		state.chatHistoryList = arr
		localStorageUtil.set('chatHistoryList', arr)
	},
	// 删除某个对话
	DELETE_CHATLIST(state, index) {
		var arr = localStorageUtil.get('chatHistoryList')
		arr.splice(index, 1)
		state.chatHistoryList = arr
		localStorageUtil.set('chatHistoryList', arr)
	},
	// 清空历史所有对话
	CLEAR_ALL_CHATLIST(state) {
		state.chatHistoryList = []
		localStorageUtil.set('chatHistoryList', [])
	},
	// 更新当前对话的最后一条信息
	UPDATE_CURRENTCHAT(state, chat) {
		var chatHistoryList = localStorageUtil.get('chatHistoryList')
		var currentchatObject = chatHistoryList[state.activeChatIndex]
		var messagesArr = currentchatObject.messages
		messagesArr.push(chat)
		state.chatHistoryList = chatHistoryList
		localStorageUtil.set('chatHistoryList', chatHistoryList)
	},
	// 更新GPT最后一条空信息
	UPDATE_GPTMESSAGE(state, content) {
		var chatHistoryList = localStorageUtil.get('chatHistoryList')
		var currentchatObject = chatHistoryList[state.activeChatIndex]
		var messagesArr = currentchatObject.messages
		messagesArr[messagesArr.length - 1].content = content
		state.chatHistoryList = chatHistoryList
		localStorageUtil.set('chatHistoryList', chatHistoryList)
	},
}

//可以处理业务逻辑【if、异步语句等等】
let actions = {}

//仓库计算属性
let getters = {}

//对外暴露小仓库
export default {
	state,
	mutations,
	actions,
	getters,
}
