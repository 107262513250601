//登录、注册模块的小仓库
// import { reqRegister, reqLogin, reqLogout } from '@/api'
import localStorageUtil from '@/utils/localStorageUtil.js'

// 初始化
var userInfo =
	(sessionStorage.getItem('userInfo') &&
		sessionStorage.getItem('userInfo') != 'undefined' &&
		JSON.parse(sessionStorage.getItem('userInfo'))) ||
	localStorageUtil.get('userInfo') ||
	{} // 账号信息
var token = sessionStorage.getItem('scw_token') || localStorageUtil.get('scw_token') || '' // 账号token
var authority =
	(sessionStorage.getItem('authority') &&
		sessionStorage.getItem('authority') != 'undefined' &&
		JSON.parse(sessionStorage.getItem('authority'))) ||
	localStorageUtil.get('authority') ||
	'' // 权限（内含Account用户信息）
// console.log("vuex初始化wanbi...", "userInfo:", userInfo, "token:", token)

let state = {
	userInfo: userInfo, // 账号信息
	token: token, // 账号token
	authority: authority, // 权限（内含Account用户信息）
}

let mutations = {
	UPDATE_USERINFO(state, resultJsonObj) {
		console.log('updateAccount刷新......', resultJsonObj)
		state.userInfo = resultJsonObj.entity
		state.token = resultJsonObj.token
		state.authority = resultJsonObj.permission
		sessionStorage.setItem('userInfo', JSON.stringify(resultJsonObj.entity))
		sessionStorage.setItem('scw_token', resultJsonObj.token)
		sessionStorage.setItem('authority', JSON.stringify(resultJsonObj.permission))

		if (resultJsonObj.rememberMe) {
			localStorageUtil.set('userInfo', resultJsonObj.entity)
			localStorageUtil.set('scw_token', resultJsonObj.token)
			localStorageUtil.set('authority', resultJsonObj.permission)
		}
	},

	UPDATE_TOKEN(state, token) {
		console.log('token刷新......', token)
		state.token = token
		sessionStorage.setItem('scw_token', token)
		localStorageUtil.get('scw_token') && localStorageUtil.set('scw_token', token)
	},

	SET_USERINFO(state, nickName) {
		state.nickName = nickName
	},

	CLEAR(state) {
		state.token = ''
		state.userInfo = ''
		state.authority = ''
		sessionStorage.clear()
		localStorageUtil.clear()
	},

	// 同步LocalStorage和SessionStorage缓存
	SYNCHRONIZE_LOCAL_TO_SESSION(state) {
		localStorageUtil.get('userInfo') &&
			localStorageUtil.get('userInfo') != 'undefined' &&
			sessionStorage.setItem('userInfo', JSON.stringify(localStorageUtil.get('userInfo')))
		localStorageUtil.get('scw_token') && sessionStorage.setItem('scw_token', localStorageUtil.get('scw_token'))
		localStorageUtil.get('authority') &&
			localStorageUtil.get('authority') != 'undefined' &&
			sessionStorage.setItem('authority', JSON.stringify(localStorageUtil.get('authority')))
	},
}

let actions = {
	//用户登录
	async userLogin({ commit, state, dispatch }, params) {
		let result = await reqLogin(params)
		if (result.code == 200) {
			result.data.rememberMe = params.rememberMe || false // 传递rememberMe
			commit('UPDATE_USERINFO', result.data)
		} else {
			//登录失败
			return Promise.reject(new Error(result.message))
		}
	},

	//退出登录的业务
	async logout({ commit, state, dispatch }) {
		//发请求通知服务器销毁当前token【学生证】
		let result = await reqLogout()
		if (result.code == 200) {
			commit('CLEAR')
		} else {
			return Promise.reject(new Error(result.message))
		}
	},

	//注册用户
	/*   async registerUser ({ commit, state, dispatch }, obj) {
          //注册接口没有返回data,不需要提交mutation
          let result = await reqRegister(obj);
          if (result.code == 200) {
              //注册成功
              return 'ok';
          } else {
              //注册失败
              return Promise.reject(new Error(result.message));
          }
      }, */
	//获取用户信息(参考代码)
	/*  async getUserInfo ({ commit, state, dispatch }) {
         let result = await reqUserInfo();
         if (result.code == 200) {
             commit('SET_USERINFO', result.data.nickName);
             return 'ok';
         } else {
             return Promise.reject();
         }
     }, */
}

let getters = {}

//对外暴露
export default {
	state,
	mutations,
	actions,
	getters,
}
