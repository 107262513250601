import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store'
import './mock/index.js'
import '@/assets/img/icon' // 这个代表全局挂载项目当中,所有组件都可以使用icons

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// Vue.config.devtools = true
Vue.use(ElementUI)

// 复制粘贴板插件
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

// 挂载parseTime全局方法
import { parseTime } from '@/utils/util'
Vue.prototype.$parseTime = parseTime

// 引入highlight
import hljs from 'highlight.js'
hljs.configure({ ignoreUnescapedHTML: true })
Vue.use(hljs)
// 封装成一个指令,highlight是指令的名称
Vue.directive('highlight', (el) => {
	let blocks = el.querySelectorAll('pre code')
	blocks.forEach((block) => {
		hljs.highlightElement(block)
		// 创建复制按钮元素
		const btn = document.createElement('span')
		btn.textContent = 'Copy'
		btn.className = 'copy-code-button'
		// 按钮点击事件监听器
		btn.addEventListener('click', async () => {
			try {
				await navigator.clipboard.writeText(block.textContent)
				// alert('已复制!')
				Vue.prototype.$message({
					message: '已复制',
					type: 'success',
					center: true,
					duration: 600,
					customClass: 'el-message-custom',
				})
			} catch (err) {
				console.error('复制失败: ', err)
			}
		})
		// 将按钮插入到当前代码块的后面
		block.parentNode.insertBefore(btn, block)
	})
})

Vue.config.productionTip = false

// chatgpt node包需要这行代码
global.Buffer = global.Buffer || require('buffer').Buffer

//按需引入写法不同的:MessageBox、Message、Loading、Notification
import { MessageBox, Message } from 'element-ui'
Vue.prototype.$msgbox = MessageBox
//消息提示框
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$message = Message

// 引入打字机效果
import VueTypedJs from 'vue-typed-js'
Vue.use(VueTypedJs)

// 定义全局XSS解决方法
// import xss from 'xss'
// Object.defineProperty(Vue.prototype, '$xss', {})

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount('#app')
