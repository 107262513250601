<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
export default {
    name: 'App'
}
</script>

<style lang="scss">
@import url(./assets/font/iconfont.css);
.iconfont {
    font-family: 'iconfont' !important;
    font-style: normal;
    font-size: 25px;
    vertical-align: middle;
    color: rgb(117, 120, 137);
    transition: 0.3s;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
* {
    padding: 0;
    margin: 0;
    font-family: Noto Sans SC, SF Pro SC, SF Pro Text, SF Pro Icons, PingFang SC, Helvetica Neue, Helvetica, Arial, sans-serif;
}

#app {
    //width: 100vw;
    height: 100vh;
    //background-color: rgb(151,157,167);
    overflow: hidden;
}
body {
    box-sizing: border-box;
}
//  去掉网页滚动条
/* ::-webkit-scrollbar {
	width: 0;
} */
::-webkit-scrollbar {
    width: 0;
    height: 0;
}
//  element.js 提示框自定义
.el-message-custom {
    min-width: 20px !important;
}

.editTopicMessage {
    max-width: 80vw !important;
    .el-message-box__header {
        padding: 10px 0 0 0 !important;
    }
}
</style>
<style>
/* 引入自定义字体 */
@font-face {
    font-family: 'Oxygen';
    src: url('@/assets/font/Oxygen-Light.ttf');
}

@font-face {
    font-family: 'Oxygen';
    src: url('@/assets/font/Oxygen-Regular.ttf');
    font-weight: 400;
}

@font-face {
    font-family: 'Oxygen';
    src: url('@/assets/font/Oxygen-Bold.ttf');
    font-weight: 700;
}

@font-face {
    font-family: 'HanziZhiMei';
    src: url('@/assets/font/HanziZhiMei.woff');
}

body {
    font-family: Oxygen, Arial, sans-serif;
}
</style>
