import Vue from 'vue'
import VueRouter from 'vue-router'

const Home = () => import('@/view/Home.vue')
const ChatWindow = () => import('@/view/pages/ChatWindow.vue')
// const MobileChat = () => import('@/view/pages/MobileChat.vue')
// const Test2Vue = () => import('@/view/pages/test/Test2Vue.vue')
// const Test4Vue = () => import('@/view/pages/test/Test4Vue.vue')
// const Test5Vue = () => import('@/view/pages/test/Test5Vue.vue')
// const Test6 = () => import('@/view/pages/test/Test6.vue')
const TestAPI = () => import('@/view/pages/test/TestAPI.vue')
// const LoadingIndex = () => import('@/components/LoadingIndex.vue')
const Config = () => import('@/view/pages/Config.vue')
const TestSSE = () => import('@/view/pages/test/TestSSE.vue')
const TestWebsocket = () => import('@/view/pages/test/TestWebsocket.vue')
const LoginMain = () => import('@/view/login/LoginMain.vue')

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		redirect: '/chat',
		component: Home,
		children: [
			{
				path: '/chat',
				name: 'ChatWindow',
				component: ChatWindow,
			},
			{
				path: '/config',
				name: 'Config',
				component: Config,
			},
		],
	},
	{
		path: '/login',
		component: LoginMain,
		/* 	children: [
			{
				path: '/chat',
				name: 'ChatWindow',
				component: ChatWindow,
			},
			{
				path: '/config',
				name: 'Config',
				component: Config,
			},
		], */
	},
]

const router = new VueRouter({
	mode: 'history',
	routes,
})

export default router
/* 
export default new VueRouter({
	mode: 'history',
	routes: [
			{
			path: '/',
			redirect: '/ChatWindow',
			component: Home,
			children: [
				{
					path: '/ChatWindow',
					name: 'ChatWindow',
					component: ChatWindow,
				},
			],
		},
		 {
			path: '/MobileChat',
			name: 'MobileChat',
			component: MobileChat,
		},
		{
			path: '/testVue',
			// name: 'TestVue',
			component: TestVue,
		}, 
		{
			path: '*',
			component: Home,
		},
	],

   

}) */
