//settings模块专享的小仓库
import { setTheme } from '@/theme/theme'
import localStorageUtil from '@/utils/localStorageUtil.js'
import MobileDetect from 'mobile-detect'

const mobileDetect = new MobileDetect(window.navigator.userAgent)
const isMobile = !!mobileDetect.mobile()
console.log('isMobile:', isMobile)

// 页面宽度小于750px时，不显示
if (!isMobile) {
	if (document.documentElement.clientWidth < 768) {
		var showLeftSideBar = false
	} else {
		var showLeftSideBar = true
	}
} else {
	var showLeftSideBar = false
}

let state = {
	//  theme仅限3种（default、dark、light）
	theme: localStorageUtil.get('theme') || process.env.VUE_APP_DEFAULT_THEME, // 主题颜色（白日模式、暗黑模式）
	showLeftSideBar: showLeftSideBar, // 显示侧边栏
	isMobile: isMobile, // 设备为手机
	apikey: localStorageUtil.get('apikey') || process.env.VUE_APP_OPENAI_API_KEY,
}

//唯一可以修改仓库数据地方【工人】
let mutations = {
	SET_THEMESTORE(state, theme) {
		state.theme = theme
		setTheme(theme)
	},
	SET_SHOW_LEFTSIDEBAR(state, showLeftSideBar) {
		state.showLeftSideBar = showLeftSideBar
	},
	// 设置apikey
	SET_APIKEY(state, apikey) {
		state.apikey = apikey
		localStorageUtil.set('apikey', apikey)
	},

	/* 
     // 以下为范例
    
    GETCATEGORY(state, category) {
		state.category = category
	},
	GETBANNERLIST(state, bannerList) {
		state.bannerList = bannerList
		// console.log('mutation修改数据')
	},
	GETFLOORLIST(state, floorList) {
		state.floorList = floorList
	}, */
}

//可以处理业务逻辑【if、异步语句等等】
let actions = {
	/* 
    // 以下为范例
    
    //商品分类的actions
    //actions地盘:可不可以书写异步语句
    async getCategory({ commit, state, dispatch }) {
        //获取服务器的数据,存储在vuex仓库中
        //reqCategory函数执行,返回的是Promise对象【pending、成功、失败】
        //await 等待成功的结果
        let result = await reqCategory();
        //判断服务器返回的状态是200->成功
        if (result.code == 200) {
            //提交mutation存储服务器数据
            commit("GETCATEGORY", result.data);
        }
    },
    //获取banner图的action
    async getBannerList({ commit, state, dispatch }) {
        //获取首页数据
        let result = await reqBannerList();
        if (result.code == 200) {
            // console.log('actions发请求')
            commit("GETBANNERLIST", result.data);
        }
    },
    //获取Floor组件的数据
    async getFloorList({ commit, state, dispatch }) {
        let result = await reqFloorList();
        if (result.code == 200) {
            commit('GETFLOORLIST', result.data);
            // console.log(result.data);
        }
    } */
}

//仓库计算属性
let getters = {}

//对外暴露小仓库
export default {
	state,
	mutations,
	actions,
	getters,
}
