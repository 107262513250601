import { themes } from './mode'
import localStorageUtil from '@/utils/localStorageUtil.js'

// 改变样式
const setStyle = (themeObj) => {
	Object.keys(themeObj).forEach((item) => {
		// 下面这个方法是定义全局的 --primaryColor 变量
		document.getElementsByTagName('body')[0].style.setProperty(`--${item}`, themeObj[item])
	})
}

// 设置主题
export const setTheme = (themeName) => {
	localStorageUtil.set('theme', themeName)
	const themeConfig = themes[themeName]
	setStyle(themeConfig)
}

// 初始化
export const initTheme = () => {
	console.log('initTheme')
	const themeName = localStorageUtil.get('theme')
	if (themeName) {
		console.log('已设置过主题:', themeName)
		setTheme(themeName)
	} else {
		console.log('设置主题:', process.env.VUE_APP_DEFAULT_THEME)
		setTheme(process.env.VUE_APP_DEFAULT_THEME)
	}
}
